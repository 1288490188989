<template>
  <div>
    <v-btn color="green" class="" icon x-large @click="dialog = true"
      ><v-icon>mdi-plus-box</v-icon></v-btn
    >
    <v-dialog v-model="dialog" persistent width="500">
      <v-card>
        <v-card-title>Add Branch</v-card-title>
        <v-card-text>
          <v-text-field
            label="Branch Name"
            v-model="form.branch_name"
          ></v-text-field>
          <v-text-field
            label="Branch Address"
            v-model="form.branch_address"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="submit" :loading="loading"
            >Submit</v-btn
          >
          <v-btn @click="dialog = false"> Close</v-btn>
          <v-spacer></v-spacer>
          <p class="caption grey--text">addBranchModal.vue</p>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      form: {},
    };
  },
  methods: {
    ...mapActions({
      add_branch: "branch/add_branch",
    }),
    submit() {
      let x = window.confirm("Are you sure you want to add this branch?");
      if (x) {
        this.add_branch(this.form).then(() => {
          alert("Branch successfully inserted!");
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
