<template>
  <v-container class="mt-16">
    <div>
      <v-card flat>
        <v-card-title class="d-flex"
          ><h2>Branches</h2>

          <v-spacer></v-spacer>
          <add-branch-modal />
        </v-card-title>
        <v-card-text>
          <v-data-table
            :items="branches"
            :headers="branch_headers"
          ></v-data-table>
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import AddBranchModal from "../components/Branches/addBranchModal.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: { AddBranchModal },
  computed: {
    ...mapGetters({
      branches: "branch/branches",
    }),
  },
  methods: {
    ...mapActions({
      get_branches: "branch/get_branches",
    }),
  },
  data() {
    return {
      branch_headers: [
        { text: "ID", value: "id" },
        { text: "Branch Name", value: "branch_name" },
        { text: "Branch Address", value: "branch_address" },
      ],
    };
  },
  created() {
    if (this.branches.length == 0) return this.get_branches();
  },
};
</script>

<style></style>
